@media (max-width: 4000px) {
  body > #root {
    height: 100vh;
    /* font-family: 'Noto Sans', regular; */
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    margin-right: 20em;
    margin-left: 20em;
    align-content: center;
  }

  .LeagueTable {
    margin-bottom: 1em;
    padding-left: 15em;
    padding-right: 15em;
    margin-left: auto;
    margin-right: auto;
  }
  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    /* min-height: 18em; */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

.teamComparisonScore {
  display: inline-block;
  padding: 0.5vw;
  /* padding-top: 8px;
  padding-bottom: 8px; */
  margin: 0.5vw;
  border: 0.1em solid #030061;
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  flex-basis: 20%;
  font-size: 0.9em;
  font-weight: 500;
  color: white;
 background-color: grey;
  /* text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35); */
  text-align: center;

  transition: all 0.2s;
}



@media (max-width: 1800px) {
  body > #root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    font-weight: bolder;
    margin-right: 15em;
    margin-left: 15em;
    align-content: center;
  }

  .LeagueTable {
    padding-bottom: 0.5em;
    padding-left: 10em;
    padding-right: 10em;
    margin-left: auto;
    margin-right: auto;
  }
  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

@media (max-width: 1200px) {
  body > #root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    font-weight: bolder;
    margin-right: 5em;
    margin-left: 5em;
    align-content: center;
  }

  .LeagueTable {
    padding-bottom: 0.5em;
    padding-left: 7.5em;
    padding-right: 7.5em;
    margin-left: auto;
    margin-right: auto;
  }
  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

@media (max-width: 800px) {
  body > #root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    font-weight: bolder;
    margin-right: 1em;
    margin-left: 1em;
    align-content: center;
  }

  .LeagueTable {
    padding-bottom: 0.5em;
    padding-left: 5em;
    padding-right: 5em;
    margin-left: auto;
    margin-right: auto;
  }
  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

@media (max-width: 600px) {
  body > #root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.4vw;
    display: flex;
    flex-direction: column;
    margin-right: 0.2em;
    margin-left: 0.2em;
    flex-basis: 100%;
    font-weight: bolder;
    align-content: center;
  }

  .LeagueTable {
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

@media (max-width: 460px) {
  body > #root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.4vw;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    /* font-weight: 900; */
    margin-right: 0.25em;
    margin-left: 0.25em;
    align-content: center;
  }

  .LeagueTable {
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: auto;
    margin-right: auto;
    
  }

  .FormSummaries{
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure both columns take equal width */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
}

body {
  background-color: white;
  /* align-content: space-between; */
  text-align: center;
  color: rgb(0, 0, 0);
  /* max-width: 1240px; */
  margin-left: auto;
  /* flex-basis: 100%; */
  margin-right: auto;
  /* margin: 10px; */
  height: 100%;
  font-weight: bolder;
  margin-top:0px;
}

.HeaderContainer{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  /* background-color: #030061; */
  /* background-image: linear-gradient( #000000, #404040); */
  color: #030061;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0px;
  vertical-align: top;
  /* padding-bottom: 1em; */
}

h1 {
  font-size: 3.5em;
  color: #030052;
}

/* h1 .logo-container{
  height: auto;
} */

.logo-container {
  display: flex;
  /* flex-basis: 50%; */
  justify-content: center;
  align-items: center;
  max-width: 30em;
  height: auto; /* Adjust as necessary */
  margin-bottom: 0em;
  /* padding: 2em; */
  /* margin-left: 3em;
  margin-right: 3em; */
  /* padding-top: 1em;
  padding-bottom: 1em; */
}

.responsive-logo {
  max-width: 9em;
  height: auto;
  /* Optional: add padding or margin if needed */
}

h2 {
  /* font-size: 2em; */
  font-weight: 500;
  color: #030061;
}

h3 {
  font-size: 1.25em;
  
  color: #030061;
  padding: 1em;
  margin: 0%
}

h4 {
  font-size: 1.2em;
  color: #030061;
  padding-top: 2em;
  padding: 1em;
  margin: 0%
}

h5 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #030061;
}

h6 {
  font-size: 1.2em;
  color: #030061;
  padding: 1em;
  padding-top: 2em;
  line-height: 2em;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}


.historicResults{
  display: flex;
  height: 5em;
  flex-basis: 100%;
  /* font-size: 1em; */
  justify-content: center;
  align-items: center;
}  

.SocialLink {
  font-size: 1.2em;
  color: #030061;
  border: 1em;
  padding-bottom: 1em;
}

.Buttons {
  /* display: flex; */
  flex-direction: row;
}



button {
  display: inline-block;
  padding: 0.75em;
  /* padding-top: 8px;
  padding-bottom: 8px; */
  margin: 1em;
  border: 0.2em solid #030061;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  flex-basis: 100%;
  width: 12em;
  height: auto;
  font-size: 1.2em;
  cursor: pointer;
  color: white;
 background-color: #030061;
  /* text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35); */
  text-align: center;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */

  transition: all 0.2s;
}

button:active {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052 inset;

}

button.GeneratePredictions{
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #016001;
  color: white;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052 */
}

button.GeneratePredictions:active{
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #004b00;
  color: white;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052 inset;
}

.disclaimerButton {
  background-color: #ffffff;
  color: #030061;
  padding: 0.5em;
  font-size: 0.8em;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0.5px 1px 0 #030052;
}

.disclaimerButton:active {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0.5px 1px 0 #030052 inset;
}

.FixtureButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: center;
  margin-bottom: 1em;
}

.FixtureButtons button {
  background-color: #ffffff;
  color: #030061;
  margin: 1em;
  cursor: pointer;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */

}

.FixtureButtons button:active {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052 inset;

}


.RowOneContainer{
  display: flex;
  flex-wrap: wrap
}

.RowOne {
  flex: 1;
  flex-basis: 50%;

  /* display: inline-block;
  width: 20em;
  vertical-align: top; */

  /* flex-direction: column; */
}

.RowTwo {
  display: inline-block;
  width: 20em;
  vertical-align: top;
  /* flex-direction: column; */
}

/* .FixtureContainer{

} */

p {
  font-size: 1em;
  color: #030061;
}

.RadioText {
  padding: 1em;
  /* font-size: 0.5em; */
}

.FormRadio {
  padding-bottom: 0.5em;
  /* font-size: 0.9em; */
}

.OddsRadios {
  display: flex;
  height: 30px;
  /* font-size: 1em; */
  justify-content: center;
  align-items: center;
}

/* .container {
  padding: 0px;
} */

.LoadingText {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  font-size: 1em;
  color: #030052;
}

.lastXGames {
  height: 100px;
  /* font-size: 1em; */
  justify-content: center;
  align-items: center;
}

.SuccessMeasure {
  height: auto;
  flex-basis: 10%;
  padding: 0.5em;
  /* font-size: 0.8em; */
  flex-grow: 1000;
  display: inline-block;
  width: 40%;
  line-height: auto;
  border-bottom: #030052 solid 1px;
}

.Span1 {
  flex-basis: 50%;
  text-align: right;
  margin-right: 10px;
}

.Span2 {
  flex-basis: 50%;
  text-align: left;
  margin-left: 10px;
}

span.MembershipInfo {
  color: #030052;
  display: block;
  font-size: 1.2em;
  padding: 1em;
}
.Explainer{
  padding-top: 1em;
  padding-top: 0.2em;
  padding-left: 3em;
  padding-right: 3em;
}

.Version {
  font-size: 0.75em;
}

.bitcoin{
  padding: 0.5em;
  padding-bottom: 1em;
  margin: 3em;
  margin-top: 5em;
  margin-bottom: 2em;
  font-size: 1.2em;
  line-height: 2em;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  background-color: #030061;
  color: white;
}

.bitcoinSymbol{
  font-size: 1.2em;
  font-weight: 2em;
}

.DisclaimerText{
  font-size: 0.75em;
  padding-bottom: 1em;
}

.AllLeagues {
  margin: 0;
}

.League {
  padding: 0.5em;
}

.DataText {
  padding: 0.75em;
  color: #030061;
}

.DataLink {
  color: #030061;
  border-bottom: 1em;
  padding-bottom: 1em;
}

.btn-container{
    padding: 1em;
    width: 1em;
    position: inherit;
}

.Social {
  padding-top: 2em;
  padding-bottom: 2em;
  display: block;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 5em;
}

.leagueName {
  font-size: 1.2em;
  font-weight: 550;
  color: white;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  margin-top: 1.5em;
  background-color:#030061;
  color: white;
  box-shadow: 0 1px 2px 0 #03006175 inset, 0 2px 2px 0 #03006175;
  cursor: pointer;
}

.ProfitableLeague{
  color: #01a501;
  font-size: 1.2em;
}

.NonProfitableLeague{
  color: #bf1000;
  font-size: 1.2em;
}

#league7432 {
	background: linear-gradient(180deg, #FE0000 16.66%,
		#FD8C00 16.66%, 33.32%,
		#FFE500 33.32%, 49.98%,
		#119F0B 49.98%, 66.64%,
		#0644B3 66.64%, 83.3%,
		#C22EDC 83.3%);  background-size: 50% auto;
  color: black;
}


.leagueName:active {
  box-shadow: 0 1px 2px 0 #030052 inset, 0 2px 2px 0 #030052 inset;
}

.DateForm{
  display: flex;
  position: inherit !important;

  flex-direction: row;
  padding: 2em;
  /* background-color: white; */
  opacity: 1;
  width: 50%;
  text-align: center;
  height: 2em;
  justify-content: center;
}


.DateForm input{
  position: inherit !important;
  background-color: white;
  opacity: 1;

}


.StatsTable {
  background-color: cyan;
}

.LeagueTable {
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.LeagueTable th {
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  width: auto;
}

.ResultsList{
  font-size: 2em;
  /* padding: 1em; */
}
.ResultsList li{
  /* padding: 0.5em; */
  margin: 0.5em;
  background-color: #030061;
  color: white;
}

.ResultButton{
  width: 100%;
  padding: 0em;
  border-radius: 0;
  margin: 0.35em;
  margin-left: 0px;
  margin-right: 0px;
}

/* .ResultButton:hover{
  width: 100%;
  padding: 0em;
  margin: 0.35em;
  margin-left: 0px;
  margin-right: 0px;
  border: 0px;
  background-color: #030061;
  border-radius: 0px;
} */

.ResultRowOverview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.75em;
  align-items: center;
}

.ResultRowOverviewSmall {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  font-size: 1.1em;
  background-color: #030061;
  color: white;
}

.ResultRowOverviewSmallW {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  font-size: 1.1em;
  background-color: #004b00;
  color: white;
}

.ResultRowOverviewSmallD {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  font-size: 1.1em;
  background-color: #030061;
  color: white;
}

.ResultRowOverviewSmallL {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  font-size: 1.1em;
  background-color: #730a00;
  color: white;
}

.columnOverviewHome {
  flex: 33.33%;
  text-align: right;
  padding-right: 2em;
}

.columnOverviewAway {
  flex: 33.33%;
  text-align: left;
  padding-left: 2em;
}

.columnOverviewHomeSmall {
  flex: 33.33%;
  text-align: right;
  /* padding-left: 0.5em; */
  padding-right: 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnOverviewAwaySmall {
  flex: 33.33%;
  text-align: left;
  padding-left: 0.75em;
  /* padding-right: 0.5em; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnOverviewScore {
  flex: 1em;
  background-color: yellow;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  color: black;
}
.columnOverviewScoreSmall {
  flex: 1em;
  background-color: yellow;
  padding: 0.25em;
  color: black;
}

.ResultRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5em;
  color: #030061;
}

.ResultRowSmall {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5em;
  color: #030061;
  text-align: center;
  font-size: 1.1em;
}

.NoGames{
  align-self: flex-start;
  font-size: 1.4em;
  text-align: center;
}

.ResultRowSmallDate {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5em;
  color: #030061;
  text-align: center;
  font-size: 1.2em;
}

.column {
  flex: 33.33%;
}

.ResultGoals {
  padding-left: 0.35em;
  padding-right: 0.35em;
  margin: 0.25em;
  background-color: yellow;
  color: black;
}

.fixture {
  display: flex;
  align-items: center;
  /* align-content: center; */
  /* justify-content: center; */
  /* width: 100%; */
  flex-direction: column;
  flex-wrap: nowrap;
  display: inline-block;
  height: auto;
  /* border: 0px; */
}

.ShortlistedGameDiv{
  display: flex;
  flex-basis: 90%;
  margin-left: auto;
  margin-right: auto;
}

.TipButton{
  /* flex-basis: 33%; */
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  padding: 0.75em;
  align-content: flex-end;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  /* margin-right: auto; */
  font-size: 0.8em;
  /* font-size: 0.75em; */
  /* background-color: white; */
  color: #030052;
  border: #030052 1px solid
  /* line-height: 1em; */
}
.UserTipsList{
  padding: 1em;
  border: #030061 1px dashed;
  margin: 0.5em;
  background-color: white;
}

.UserTipsListItems{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.1em;
}

strong {
  font-weight: bold !important;
}

/* Base styles for the checkbox */
.star {
  appearance: none; /* Remove default checkbox */
  font-size: 1.5em;
  width: 1.5em;
  height: 1em;
  cursor: pointer;
  border: none;
  background: none;
  text-align: center;
  margin-bottom: 1.5em;
}

/* Default empty star */
.star:before {
  content: "\2606"; /* Unicode for empty star */
  color: gray;
  font-size: 1.5em;
  display: inline-block;
}

/* Change to filled star when checked */
.star:checked:before {
  content: "\2605"; /* Unicode for filled star */
  color: gold;
}

.pointsGapExplainer{
  color: #030061;
}

.individualFixtureContainerundefined {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  background-color: white;
  border: 0.1em solid #030061;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  margin-bottom: 1em;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052;
}

.individualFixtureContainer:active, input:focus{
  /* box-shadow:2px 2px 2px 2px grey; */
  
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 #030052 inset;
}

.GameStats {
  flex-basis: 25%;
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  padding: 0.75em;
  align-content: flex-end;
  align-items: center;
  margin-left: auto;
  /* margin-right: auto; */
  font-size: 0.9em;
  /* font-size: 0.75em; */
  background-color: white;
  color: #030052;
  border: #030052 1px solid
  /* line-height: 1em; */
}

.GameStatsTwo {
  flex-basis: 25%;
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  padding: 0.75em;
  align-content: center;
  align-items: center;
  /* margin-left: auto; */
  margin-right: auto;
  font-size: 0.9em;
  /* font-size: 0.75em; */
  background-color: white;
  color: #030052;
  border: #030052 1px solid
  /* line-height: 1em; */
}

*[class^='individualFixture'] {
  display: flex;
  flex-basis: 100%;
  background-color: white;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em;
  /* padding-bottom: 0.5em; */
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  /* margin-bottom: 1em; */
  /* border: black 0.25px solid; */
}


.individualFixturefalse {
  display: flex;
  flex-basis: 100%;
  background-color: white;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em;
  /* padding-bottom: 0.5em; */
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  /* margin-bottom: 1em; */
  /* border: black 0.25px solid; */
}


.individualFixturetrue {
  display: flex;
  flex-basis: 100%;
  background-color: rgb(212, 212, 212);
  align-items: center;
  align-content: center;
  color: rgb(61, 61, 61);
  padding-top: 0.5em;
  /* padding-bottom: 0.5em; */
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  /* margin-bottom: 1em; */
  /* border: black 0.25px solid; */
}

.individualFixtureContainerfalse {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  background-color: white;
  border: 0.1em solid #030061;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  margin-bottom: 1em;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052;
}


.individualFixtureContainertrue {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  background-color: rgb(212, 212, 212);
  border: 0.1em dashed #030061;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  margin-bottom: 1em;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
}

.individualFixturecomplete {
  display: flex;
  flex-basis: 100%;
  background-color: #dad7d7a0;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.25em;
  /* padding-bottom: 0.25em; */
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */
  /* margin-bottom: 0.5em; */
  
  /* border: 1em; */
}


.FixtureList{
  background-color: #030061, transparent;
}

.individualFixtureGap {
  display: flex;
  flex-basis: 100%;
  background-color: #ffffff;
  align-items: center;
  align-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 #030052;
  margin-bottom: 0.5em;
  color: #030061;  
  /* border: 1em; */
}

.FixtureSpacing {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.BTTSFixture {
  display: flex;
  height: 2em;
  flex-basis: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* font-size: 0.4em; */
}

.highlight {
  display: flex;
  height: 2em;
  flex-basis: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* font-size: 0.4em; */
  color: yellow;
}

.HomeBadge {
  border-color: #393d3f;
  height: 2em;
  order: 1;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  flex-basis: 1%;
}

.AwayBadge {
  border-color: #393d3f;
  height: 2em;
  order: 5;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  flex-basis: 1%;
}

.break {
  flex-basis: 100%;
  height: 0;
}


*[class^='HomeOdds'] {
  order: 0;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color: white;
  width: 3.5em;
  border-radius: 10px;
  font-weight: bolder;
  margin-left: 1em;
}

.oddsHomeExplainer {
  order: 0;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color: white;
  width: 4.5em;
  border-radius: 10px;
  font-weight: bolder;
  margin-left: 1em;
}

.emptyHomeTeam {
  height: auto;
  /* width:30%; */
  order: 1;
  /* font-size: 0.7em; */
  flex-grow: 0;
  flex-basis: 40%;
  flex-shrink: 2;
  display: inline-block;
  text-align: right;
  padding-left: 0.25em;
  font-weight: bolder;
}

.emptyAwayTeam {
  height: auto;
  flex-basis: 40%;
  order: 6;
  /* font-size: 0.7em; */
  flex-grow: 0;
  flex-shrink: 2;
  display: inline-block;
  text-align: left;
  padding-right: 0.25em;
  font-weight: bolder;
}

.HomeOddscomplete {
  order: 0;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color:white;
  width: 3.5em;
  border-radius: 10px;
  font-weight: bolder;
  margin-left: 1em;
}

.GetMatchStatText {
  padding-bottom: 1em;
  color: #030061;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1em;
  font-size: 0.8em;
}

*[class^='AwayOdds']{
  order: 7;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color: white;
  width: 3.5em;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 1em;
}

.oddsAwayExplainer {
  order: 7;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color: white;
  width: 4.5em;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 1em;
}

.AwayOddscomplete {
  order: 7;
  /* font-size: 0.5em; */
  padding: 0.5em;
 background-color: #030061;
  color: white;
  width: 3.5em;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 1em;
}

.homeTeam {
  height: auto;
  /* width:30%; */
  order: 1;
  /* font-size: 0.7em; */
  flex-grow: 0;
  flex-basis: 40%;
  flex-shrink: 2;
  display: inline-block;
  text-align: right;
  padding-left: 0.25em;
  font-weight: bolder;
}

.awayTeam {
  height: auto;
  flex-basis: 40%;
  order: 6;
  /* font-size: 0.7em; */
  flex-grow: 0;
  flex-shrink: 2;
  display: inline-block;
  text-align: left;
  padding-right: 0.25em;
  font-weight: bolder;
}

.ExplainerHeaders {
   display: flex;
  /* align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row; */

  justify-content: center;
  padding-top: 2em;

}

.ScoreHeader {
  flex-basis: 50%;
  font-size: 0.9em;
  /* margin: 0.1em;
  flex-wrap: wrap;
  padding: 0.5em; */
  
  /* background-color: white; */
  color: white;
  text-align: right;
  /* border-right: white;
  border-style: solid;
  border-right-width: 0.5px; */
  border: none;
  padding: 0.5em;
}

.PredictionHeader {
  flex-basis: 50%;
  font-size: 0.9em;

  /* background-color: white; */
  color: white;
  /* padding-left: 1em; */
  
  text-align: left;
  border: none;
  padding: 0.5em;

}

.ArrowOne{
  text-align: right;
  padding-right:  2em;
  padding-top: 1em;
}

.ArrowTwo{
  text-align: left;
  padding-left: 2em;
  padding-top: 1em;

}

.divider,
.score {
  height: auto;
  flex-basis: 10%;
  max-width: 5em;
  order: 3;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color: #030061;
  border: black 0.25px solid;
  border-radius: 10px;
  margin: 0.2em;
}

.KOTime {
  height: auto;
  flex-basis: 10%;
  max-width: 5em;
  order: 3;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color: #030061;
  border-radius: 10px;
  margin: 0.2em;
}

.Result {
  height: auto;
  flex-basis: 10%;
  max-width: 5em;
  order: 3;
  padding: 0.5em;
  display: inline-block;
  color: black;
  background-color: rgb(242, 210, 1);
  margin: 0.2em;
  border: black 0.25px solid;
  border-radius: 10px;
  box-shadow: #030052;
}

.ExplainerContainer {
  display: flex;
  /* border: 0.1em solid #030061; */
  padding-top: 1em;
  padding-bottom: 0.5em;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  font-size: 0.9em;
}

.scoreExplainer {
  height: auto;
  flex-basis: 10%;
  max-width: 5.2em;
  min-width: 5.2em;
  order: 3;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color:#030061;
  margin: 0.2em;
  border: black 0.25px solid;
  border-radius: 10px;
  box-shadow: #030052;
}

.HomeResults {
  /* padding-right: 1em; */
  font-size: 1em;
  padding: 1em;
  text-align: center;
}

.HomeResults li {
  padding-bottom: 1em;
  border-bottom: #030061 1px solid
}

.AwayResults {
  /* padding-left: 1em; */
  font-size: 1em;
  padding: 1em;
  text-align: center;
}

.AwayResults li {
  padding-bottom: 1em;
  border-bottom: #030061 1px solid
}

.GameDate {
  padding-bottom: 0.25em;
  padding-top: 0.5em;
}

.Omitted {
  height: auto;
  flex-basis: 10%;
  order: 4;
  padding: 0.5em;
  max-width: 5em;
  display: inline-block;
  color: black;
  background-color: rgb(212, 212, 212);
  border: black 0.25px dashed;
  border-radius: 10px;
  margin: 0.2em;
}

.IncorrectScore {
  height: auto;
  flex-basis: 10%;
  max-width: 5em;
  order: 4;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color: #730a00;
  border: black 0.25px solid;
  border-radius: 10px;
  margin: 0.2em;
}

.CorrectScore {
  height: auto;
  flex-basis: 10%;
  max-width: 5em;
  order: 4;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color:  #016001;
  border: black 0.25px solid;
  border-radius: 10px;
  margin: 0.2em;
  
}

.ExactScore {
    height: auto;
    flex-basis: 10%;
    order: 4;
    padding: 0.5em;
    max-width: 5em;
    display: inline-block;
    color: gold;
    background-color:  #016001;
    border: black 0.25px solid;
    border-radius: 10px;
    margin: 0.2em;
}

.predictionExplainer {
  height: auto;
  flex-basis: 10%;
  max-width: 5.2em;
  min-width: 5.2em;
  order: 4;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color:#030061;
  border: black 0.25px solid;
  border-radius: 10px;
  margin: 0.2em;
}

.hidden {
  display: none;
}

/* .RowOneContainer {
  max-width: 1200px;
  vertical-align: middle;
} */

.Collapsable {
  /* font-size: 2vw; */
  /* flex-basis: 50%; */
  margin: 0em;
  padding: 0em;
  /* margin: 0.25em; */
  flex-wrap: wrap;
  height: auto;
  /* border: #030061 0.1px dashed; */
  /* border-radius: 5px; */
  background-color: white;
  /* border: #030061 1px dashed; */
  /* box-shadow: 4px 4px 4px 4px rgba(0.2, 0.2, 0.2, 0.2), 0 6px 20px 0 #294A66; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px; */
}

.Collapsible__contentOuter {
  width: 90%;
  margin: auto;
}

.FilterContainer {
  width: 100%;
}

.DetailedFixtures{
  align-self: center;
  justify-content: center;
  font-size: 0.8em;
}

.flex-element{
  display: flex;
  align-self: center;
  justify-content: center;
}

.Collapsible{
  display: block;
  color: #030061;
}

.FiltersSelected{
  padding: 1em;
  color: #030052;
}

.FiltersSelectedList li{
  padding: 0.25em;
}

.StatsDiv{
  background-color: #e2e2e26c;
  margin-bottom: 0.5em;
  font-weight: bolder;
}

.Aligned {
  margin-top: 1em;
  padding-top: 0.5em;
  padding-bottom: 2em;
}

li .Aligned {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.BTTSResults{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 6.5em;
  padding-left: 6.5em;

}

.BTTSTrue{
  color: #01a501;
  padding: 0.75em;
  padding-bottom: 0.5em;
  font-size: 1.2em;
}

.BTTSFalse{
  color: #970d00;
  padding: 0.75em;
  padding-bottom: 0.5em;
  font-size: 1.2em;
}

.RadioContainer {
  align-items: center;
  flex-direction: row;
}

.LastXGames {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.1em;
  /* flex-direction: column; */
  /* flex-basis: 50%; */
  /* font-size: 3vw; */
}

/* 
.Stat {
  padding: 5px;
} */
.MuiPaper-root{  
  margin: 0px !important;
  border: 0px !important;
}

.MuiPaper-elevation1{
  box-shadow: none !important;
}

.LeagueStatisticsHeader{
  font-size: 2.5em;
  background-color: #e2e2e26c;
  color:#030061;
  padding-top: 1em;
  padding-bottom: 0.5em
}

.LeagueStatistics {
  display: flex;
  flex-wrap: wrap;
  font-size: 2em;
  align-items: center;
  background-color: #e2e2e26c;
  color: black;
  /* flex-direction: column;
  align-items: center;
  flex-basis: 100%; */
}

.LeagueStatsColumn {
  padding-top: 1em;
  flex: 40%;
  background: white;
  color: #030061;
  margin: 0.25em;
  padding: 1em;
}

.LeagueStatistics li {
  color: #030061;
  padding: 0.5em;  
}

.TeamNameHeader{
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-bottom: 0em;
  padding-top: 0em;
}

.TopScorersColumn {
  padding-top: 2em;
  flex-direction: row;
  flex: 50%; 
  padding-bottom: 1em;
  padding: 1em;
  margin: 0.25em;
  background: white;
  color: #030061
}


.StatsContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  text-align: center;
  /* align-items: end; */
}

.HomeBadgePrevious {
  border-color: #393d3f;
  height: 1.5em;
  order: 3;
  padding: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 1%;
}

.AwayBadgePrevious {
  border-color: #393d3f;
  height: 1.5em;
  padding: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 1%;
  order: -2;
}

.HomeBadgePreviousAway {
  border-color: #393d3f;
  height: 1.5em;
  order: -1;
  padding: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 1%;
}

.AwayBadgePreviousAway {
  border-color: #393d3f;
  height: 1.5em;
  padding: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 1%;
  order: 2;
}



.IndividualPreviousResultGameHome{
  align-items: center;
  align-content: center;
  color: black;
  /* padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.5em; */
  flex-wrap: wrap;
}


.IndividualPreviousResultGameAway{
  align-items: center;
  align-content: center;
  color: black;
  /* padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em; */
  flex-wrap: wrap;
}

.PastGameDateHome{
  text-align: center;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 0.9em;
}

.PastGameDateAway{
  text-align: center;
  padding-left: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 0.9em;
}

.GameWon{
  color: #01a501;
  padding-bottom: 1em;
}

.GameLost{
  color: #bf1000;
  padding-bottom: 1em;
  font-weight: 200;
}

.GameDrawn{
  padding-bottom: 1em;
}





a.PreviousMatchLink{
  text-decoration-thickness: 0;  
  text-underline-offset: 0.2em;
  text-decoration-color: #030061;
  margin-bottom: 1em;
}

/* .PreviousResultHome {
  display: flex;
  flex-basis: 50%;
  background-color: #e2e2e26c;
  align-items: center;
  align-content: center;
  color: black;
  flex-direction: row-reverse;
  text-align: center;
  padding-bottom: 1.5em;
}

.PreviousResultAway {
  display: flex;
  flex-basis: 50%;
  background-color: #e2e2e26c;
  align-items: center;
  align-content: center;
  text-align: center;
  color: black;
  padding-bottom: 1.5em;

} */

.LastGameHeader {
  font-weight: bolder;
  color: #030052;
  padding-bottom: 1em;
  font-size: 1.1em;
}



.CollapsableDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  /* font-size: 2.3vw; */
  align-items: center;
}

.InsightsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: top;
}

.BTTSPotential {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  /* font-size: 2.5vw; */
}

.InsightsExplainer{
  display: block;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1.2em;
}

.InsightsHome {
  text-align: right;
  width: 45%;
  padding-right: 1em;
}

.InsightsAway {
  text-align: left;
  width: 45%;
  padding-left: 1em;

}

.InsightsHome li {
  padding-top: 1em;
  padding-right: 1em;
}

.InsightsAway li {
  padding-top: 1em;
  padding-left: 1em;
}

.InsightsHome lh {
  padding: 0.25em;
  margin-right: 0.5em;
  font-size: 1.2em;
}

.InsightsAway lh {
  padding: 0.25em;
  margin-left: 0.5em;
  font-size: 1.2em;
}

.WinningTeams {
  background-color: #01a501;
  color: white;
}

.LosingTeams {

  background-color: #bf1000;
  color: white;
}

.KeyStatsOne {
  flex-direction: column !important;
  text-align: right;
  margin-right: 0.5em;
  flex-basis: 49%;
  background-color:#030061;
  color: white;
  margin-left: auto;
  margin-bottom: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.KeyStatsTwo {
  flex-direction: column !important;
  text-align: left;
  flex-basis: 49%;
  margin-left: 0.5em;
  background-color:#030061;
  color: white;
  margin-right: auto;
  margin-bottom: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}


/* .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
} */

.flex-container{
  display: flex;
  align-items: stretch;
  justify-content: center;
  color: #030052;
}




.AllStats{
  /* align-items: flex-end; */
}
.RecentResults{
  /* align-items: flex-end; */
}

.flex-container-similar{
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
}

.flex-childOne {
  flex-direction: column;
  text-align: right;
  padding-right: 0.5em;
  flex-basis: 45%;
} 

.flex-childOneOverview {
  flex-direction: column;
  text-align: right;
  padding-right: 0.5em;
  flex-basis: 45%;
  /* min-width: 16em;
  max-width: 21em; */
} 

.flex-childOneOverviewSmall {
  flex-direction: column;
  text-align: right;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 50%;
  font-size: 0.75em;
  /* min-width: 16em;
  max-width: 21em; */
} 

.flex-childTwoOverview {
  flex-direction: column;
  text-align: left;
  padding-left: 0.5em;
  flex-basis: 45%;

  /* width: auto;
  min-width: 16em;
  max-width: 21em; */
} 

.flex-childTwoOverviewSmall {
  flex-direction: column;
  text-align: left;
  padding-left: 0.25em;
  padding-right: 0.25em;
  flex-basis: 50%;
  font-size: 0.75em;
  /* width: auto;
  min-width: 16em;
  max-width: 21em; */
} 

.flex-childOneOverview .W {
  border: solid 1px #030061;
  padding: 0.5em;
} 

.flex-childOneOverview .D {
  border: solid 1px #030061;
  padding: 0.5em;
} 

.flex-childOneOverview .L {
  border: solid 1px #030061;
  padding: 0.5em;
} 
      

.flex-childTwoOverview .W{
  border: solid 1px #030061;
  padding: 0.5em;
} 

.flex-childTwoOverview .D{
  border: solid 1px #030061;
  padding: 0.5em;
} 

.flex-childTwoOverview .L{
  border: solid 1px #030061;
  padding: 0.5em;
} 

.flex-childOne li:nth-child(odd){
  background-color: white
}

.flex-childTwo {
  flex-direction: column;
  text-align: left;
  flex-basis: 45%;

  padding-left: 0.5em;
} 

.flex-childTwo li:nth-child(odd){
  background-color: white
}


.PreviousResults{
  display: flex;
  flex-direction: column;
  height: auto;
  line-height: 2em;
  padding-top: 1em;
  justify-content: center;
  text-align-last: center;
  color: black;
  margin-left: auto;
}

.W {
  background-color: rgba(22, 165, 22, 0.094);
  padding: 1em;
  margin-bottom: 0.5em;
  border: solid 1px #030061;
  text-align: center;
  /* padding-left: 7px;
  padding-right: 7px; */
}

.L {
  background-color: #970d001d;
  padding: 1em;
  margin-bottom: 0.5em;
  border: solid 1px #030061;
  text-align: center;

}

.D {
  background-color: #cc7a0028;
  padding: 1em;
  margin-bottom: 0.5em;
  border: solid 1px #030061;
  text-align: center;

}


.W li {
  padding: 0.5em;
  text-align: center;
}

.D li {
  padding: 0.5em;
  text-align: center;
}

.L li {
  padding: 0.5em;
  text-align: center;
}


/* .column {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex: 1;
} */
/* 
.ColumnOne{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  text-align: end;
  padding-right: 0.25em;
  padding-left: 0.5em;
}

.ColumnTwo{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  text-align: start;
  padding-right: 0.5em;
  padding-left: 0.25em;
} */


.IndividualPastFixtureHome{
  text-align: left;
}

.IndividualPastFixtureAway{
  text-align: right;
}

.KeyStatsHome{
  padding-top: 0.5em;
  padding-bottom: 2em;
  /* padding-right: 0.25em; */
  padding-left: 0.5em;
}

.KeyStatsHome li{
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-right: 0.5em;
}

.KeyStatsAway{
  padding-top: 0.5em;
  padding-bottom: 2em;
  padding-right: 0.5em;
  /* padding-left: 0.5em; */
}

.KeyStatsAway li{
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 0.5em;
}

.TestingLayout{
  flex-direction: row;
}

.IndividualPreviousResultHome{
  display: flex;
  text-align: end;
  flex-basis: 100%;
}

.IndividualPreviousResultAway{
  display: flex;
  text-align: start;
  flex-basis: 100%;
}


.PastFixtures{
  display: flex;
  flex-direction: column;
}

.PastResultsHome{
  display: flex;
  text-align: start;
}

.PastResultsAway{
  display: flex;
  text-align: end;
}

.LineupsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.HomeLineup {
  margin-right: 5px;
  padding-top: 1em;
  text-align: right;
  width: 45%;
  background-color: #e2e2e26c;
  padding-right: 0.2em;
  /* padding-right: 20px; */
}

.HomeLineup li {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.AwayLineup {
  margin-left: 5px;
  padding-top: 1em;
  text-align: left;
  width: 45%;
  background-color: #e2e2e26c;
  padding-left: 0.2em;
}

.AwayLineup li {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.Home {
  /* padding-left: 5px; */

  padding-right: 0px;
  flex-basis: 50%;
  border-right: 1px solid #e2e2e26c;

}



.Home li {
  padding: 0.8em;
  list-style: none;
  background: #e2e2e26c;
  color: black;
  /* padding-left: 1em; */
}

.Home li:nth-child(odd) { 
  background: #dedddd;
  color: black;
}

.Home li.Form {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.Home li.FormHomeOrAway {
  padding-top: 1.5em;
}


/* 

.Away {
  padding-left: 0px;
  flex-basis: 50%;
} */
/* 
.Away li {
  padding: 0.8em;
  margin: 0px;
  list-style: none;
  background: #e2e2e26c;
  color: black;
} */

.Away li.Form {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.Away li.FormHomeOrAway {
  padding-top: 1.5em;
}


canvas {
  /* padding-top: 1em;
  padding-bottom: 1em; */
  max-height: 36em;
  max-width: 36em;
  display: inline !important;
  margin: 1em; 
  color: #030052;
}

.FormSummaryHome {
  /* display: flex; */
  /* height: auto; */
  min-height: 11em;
  /* vertical-align: middle; */
  background-color: #e2e2e200 !important;
  /* max-width: 21em; */
  /* justify-content:flex-end;
  align-items: flex-start;
  background-color: #e2e2e26c !important; */
  line-height: 1.5em;
  padding-bottom: 0em;
  /* margin-left: auto; */
}




.Away li:nth-child(odd) { 
  background: #dedddd;
  color: black;
}

li.FormHeader {
  padding-top: 1em;
  /* margin-top: 1em; */
}

.win {
  background-color: rgb(22, 165, 22);
  padding: 0.7em;
  margin-top: 1em;
  /* padding-left: 7px;
  padding-right: 7px; */
  margin: 0.2em;
  color: white;
  font-weight: 700;
}

.loss {
  background-color: #970d00;
  padding: 0.7em;
  margin-top: 1em;
  padding-left: 0.8em;
  padding-right: 0.8em;

  /* padding-left: 7px;
  padding-right: 7px; */
  margin: 0.2em;
  color: white;
  font-weight: 700;
}

.draw {
  background-color: #cc7a00;
  padding: 0.7em;
  margin-top: 1em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  /* padding-left: 7px;
  padding-right: 7px; */
  margin: 0.2em;
  color: white;
  font-weight: 700;
}

.GoalTotal{
  background-color: #030061;
  color: white;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-left: 0.2em;
  margin-right: 0.2em;

}

.winLeague {
  background-color: rgb(14, 143, 14);
  padding: 0.3em;
  margin-top: 0em;
  /* padding-left: 7px;
  padding-right: 7px; */
  width: 1.2vw;
  margin: 0.25vw;
  color: white;
  font-weight: 700;
}

.lossLeague {
  background-color: #970d00;
  padding: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0em;
  /* padding-left: 7px;
  padding-right: 7px; */
  width: 1.2vw;
  margin: 0.25vw;
  color: white;
  font-weight: 700;
}

.drawLeague {
  background-color: #cc7a00;
  padding: 0.3em;
  margin-top: 0em;
  padding-left: 0.4em;
  padding-right: 0.4em;
  /* padding-left: 7px;
  padding-right: 7px; */
  width: 1.2vw;
  margin: 0.25vw;
  color: white;
  font-weight: 700;
}

li.TeamScored{
  padding-top: 1em;
}

li.FormHeader{
  padding-bottom: 1em;
}

.MatchTime {
  padding: 1em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: 1.1em;
  color: #030052;
}

.FormExplainer {
  padding-bottom: 1em;
  text-align: center;
  background-color: #e2e2e26c;
}

.PredictionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}

.PredictionsOfTheDay {
  order: 1;
  align-self: center;
}

.DecrementButton {
  order: 2;
  font-weight: 700;
  font-size: 1.3em;
  align-self: flex-start;
  flex-basis: 3em;
  max-width: 5em;
  min-width: 3em;
  margin-right: 2em;
}

.IncrementButton {
  order: 0;
  font-weight: 700;
  font-size: 1.3em;
  align-self: flex-start;
  flex-basis: 3em;
  max-width: 5em;
  min-width: 3em;
  margin-left: 2em;
}

.RiskText {
  flex-direction: row;
}

.Under25Games {
  font-size: 0.8em;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  color: #030061;
}

.Over25Games {
  padding-top: 2em;
  font-size: 0.8em;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  color: #030061;
}

.HowToUse {
  padding: 1.2em;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 1em;
  border: 0.1em solid #030061;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  /* font-size: 0.8em; */
  font-weight: 500;
  color: white;
 background-color: #030061;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;

  transition: all 0.2s;
}

.StyleOfPlay{
  /* min-height: 2.5em; */
  margin-bottom: 0;
  padding-top: 1em !important;
  padding-bottom: 0.75em !important;

}

.BestPredictions {
  padding: 1em;
  border: #030061 1px dashed;
  margin: 0.5em;
  background-color: white;
}

.BestPredictionsExplainer {
  padding-bottom: 0.2em;
  font-size: 1.1em;
}

.BestPredictions li {
  font-size: 1.1em;
  padding-top: 0.5em;
}

.BTTSGames lh {
  /* font-size: 1em; */
  font-size: 1.1em;
}

.BTTSGames {
  border: #030061 1px dashed;
  padding: 1em;
  margin: 0.5em;
  background-color: white;
}

.BTTSGames li {
  /* font-size: 1em; */
  padding-top: 1em;
  font-size: 1.1em;
}

.fact {
  padding: 5px;
}

ul {
  padding: 0px;
  list-style: none;
}


.BestPredictions li {
  /* font-size: 0.8em; */
  font-size: 1.1em;
  padding-top: 1em;
}

.PredictionsOfTheDay li {
  /* font-size: 0.8em; */
  font-size: 1.1em;
  padding-top: 1em;
}

.LongshotPredictions {
  padding: 1em;
  border: #030061 1px dashed;
  margin: 0.5em;
  background-color: white;
}

.LongshotPredictions li {
  /* font-size: 0.8em; */
  font-size: 1.1em;
  padding-top: 1em;
}

.XGDiffTips {
  padding: 1em;
  border: #030061 1px dashed;
  margin: 0.5em;
  background-color: white;
}

.XGDiffTips lh {
  font-size: 1.2em;
}

.XGDiffTips li {
  /* font-size: 0.8em; */
  padding-top: 1em;
  font-size: 1.1em;
}

.LongshotPredictions lh {
  font-size: 1.2em;
}

.over25ActualOutcome{
  padding: 0.5em;
}

.Won {
  color: #01a501;
  font-size: 1.2em;
  /* display: flex; */
}


.Won1 {
  color: #01a501;
}

.Lost {
  color: #bf1000;
  font-size: 1.2em;
}

.TipGame {
  padding-top: 0.25em;
}

.Lost1 {
  color: #bf1000;
}

.bttsWon {
  color: #01a501;
}

.bttsLost {
  color: #bf1000;
}

.AccumulatedOdds {
  padding-top: 1em;
  font-size: 1.2em;
}

.kofitext{
  font-size: min(max(1em, 2vw), 0.8em);  
  font-weight: bolder !important;
}

.kofitext img.kofiimg {
  width: 10px !important;
}

.kofi-button {
  border: 0.1em solid #030061;
  font-family: 'Open Sans', sans-serif !important;
  min-width: 5em !important;
  line-height: 1.5em !important;
  color: black !important;
} 

img.BadgePrevious {margin-right: 1em;}

a.kofi-button {
  border: 0.1em solid #030061;

}

a {
  color: white;
  font-size: 1.2em;
  padding: 0.5em;
}

.kofi-button:hover {
  border-color: #ff0000;
  border-radius: 1em;
}


.GlobalStat {
  border-bottom: #172A3A 1px solid;
  border-width: 0.1px;
  padding-bottom: 1em;
}

p.TeamName {
  font-size: 1.1em;
  font-weight: 900;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

table,
th,
td {
  border-collapse: collapse;
  /* border-top: groove; */
  padding: 0.25em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
  text-align: center;
}


.SortedColumn{
  background-color: #030061;
  height: auto;
  width: 6em;
  vertical-align: middle;
  text-align: center;
  font-size: 1.8em;
  line-height: 1.8em;
  /* padding-left: 1em;
  padding-right: 1em; */
  margin: 0em;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
}


.MuiTable-root {
  border: none;
}

.MuiTable-root, Table{
  margin-top: 0px !important;
}

button:hover {
  border-color: #ff6f00;
  /* border-radius: 45px; */
}

.disclaimerButton:hover {
  border-color: #030061;
  /* border-radius: 45px; */
}

.content h3,
.content p {
  margin: 0;
}

#spinner {
  visibility: visible;
  width: 80px;
  height: 80px;

  border: 2px solid #e91515;
  border-top: 3px solid #393d3f;
  border-radius: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  animation: spin 1s infinite linear;
}

.H2HStats{
  /* margin: 1em; */
  text-align: center;
}

.H2HStat{
  padding: 5px;
}

.BTTSStats {
  white-space: pre;
}

.PreviousMatchStats{
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* margin-left: 1em;
  margin-right: 1em; */
  padding-bottom: 15px;
}

.PreviousMatchStats li{
  text-align: center;
  padding: 7px;
}

.Stadium{
  padding-top: 2em;
  padding-bottom: 0.5em;
  text-align: center;
  color:#030052;
  margin: auto;
  font-size: 1em;
  height: 2em;
  flex-direction: row;
  flex-basis: 90%;
}

li .Stadium{
  padding-top: 2em;
}


.LastGameDetail{
  /* padding-top: 0.5em; */
  padding-bottom: 0.5em;
  width: 100%;
  text-align: center;
  color: black;
  margin: auto;
  font-size: 1em;
  height: 1em;
}

.LastGameOverview{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0em;
  background-color: #030061;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;
  align-items: center;
}

.secondToLastGameDetail{
  padding-top: 1em !important;
  padding-bottom: 0.5em !important;
  text-align: center;
  color: black;
  margin: auto;
  font-size: 1em;
  height: 2em;}

.LastGameHome {
  flex: 33.33%;
  text-align: right;
  padding-right: 2em;
  color: white;
}

.LastGameAway {
  flex: 33.33%;
  text-align: left;
  padding-left: 2em;
  color: white;
}

.LastGameScore {
  flex: 1em;
  background-color: yellow;
  color: black;
  padding: 0.25em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  /* margin: 0.5em; */
}




.StatsTable{
  /* width: 75%; */
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: .5em;
  align-items: stretch;
  background-color: #030052;
  /* margin: 1em; */
}

.ResultsStatsTable{
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  justify-content: center;
  width: 100%;
  background-color: #030061;
  color: white;
  border: white;
}

.ResultsStatsTable th{
  padding: 1em;
}

.ResultsStatsTable td{
  padding: 0.75em;
}

.TrendsHome{
  margin-left: 5em;
  margin-right: 5em;
  padding-bottom: 5px;
}

.TrendsAway{
  margin-left: 5em;
  margin-right: 5em;
  padding-top: 5px;
  padding-bottom: 10px;

}

.MoreDetailsButton{
  font-size: 0.9em;
  margin-bottom: 1em;
}

.MoreStats{
  font-size: 1em;
  margin-top: 2em;
}

.attackOoU{
  line-height: 2em;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#spinner.hide {
  visibility: hidden;
}

#cards {
  margin: 10px;
}

section {
  width: 50%;
  min-height: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;
}

.MainLoading {
  padding: 2em;
  color:#030061
}



/* section::after {
  width: 42px;
  height: 42px;

  border: 1px solid var(--primary);

  top: 11px;
  left: 11px;
} */

.dark {
  /* display: flex; */
  flex-basis: 30%;
  /* font-size: 1.2em; */
  --primary: #EC9A29;
  --other: hsl(0, 0%, 90%);
}

.dark2 {
  /* display: flex; */
  flex-basis: 30%;
  /* font-size: 1.2em; */
  --primary: #EC9A29;  
  --other: hsl(0, 0%, 90%);
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  color: #030061;
  margin: 12px 0;

  cursor: pointer;
  position: relative;
}

.Hidden {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.design {
  width: 1em;
  height: 1em;
  /* padding: 0.5em; */
  border: 2px solid var(--other);
  border-radius: 100%;
  margin-right: 0.2em;
  border-color: black;
  background-color: white;
  position: relative;
}

.design::before,
.design::after {
  content: "";
  display: block;

  width: inherit;
  height: inherit;

  border-radius: inherit;

  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}

.design:before {
  background: var(--other);
  opacity: 0;
  transition: 0.3s;
}

.design::after {
  background: var(--primary);
  opacity: 0.4;
  transition: 0.6s;
}

/* checked state */
input:checked + .design::before {
  opacity: 1;
  transform: scale(0.6);
}

/* other states */
input:hover + .design,
input:focus + .design {
  border: 1px solid var(--primary);
}

input:hover + .design:before,
input:focus + .design:before {
  background: var(--primary);
}

input:hover ~ .text {
  color: var(--primary);
}

input:focus + .design::after,
input:active + .design::after {
  opacity: 0.1;
  transform: scale(2.6);
}

.DonationButton{
  padding-bottom: 2em;
}


/* Team page */
/* .TeamStatsContainer{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
} */

.custom-checkbox {
  display: flex;
  align-items: center;
}

.hidden-checkbox {
  display: none;
}

.checkbox-wrapper {
  width: 12px;
  height: 12px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.custom-checkbox-box.checked::before {
  content: '✔';
  font-size: 18px;
}


.TeamStatsContainer h4{
  font-size: 2vw;
}



.TeamStatsContainer canvas{
  /* min-width: 35%; */
  border: 1em;
  flex-basis: 100%;
}

.FixtureHeadingContiner{
  display: flex;
  flex-direction: row;
  font-size: 3vw;
  padding-top: 2em;
  padding-bottom: 0em;
  line-height: 2em;
  flex-wrap: wrap;
  color: #030061;
  align-items: center;
  order: 1;
  justify-content: center;
  /* flex-basis: 100%;

  background-color: white;
  align-items: center;
  align-content: center;
  color: black;
  padding-top: 0.5em; */
}

.FixtureHeading{

}
 .HomeTeamBadge {
  order: 0;
  border-color: #393d3f;
  height: 3vw;
  width: 3vw;
  padding-right: 1em;
  /* padding-left: 0.5em;
  padding-right: 0.5em; */
  flex-basis: 1%;
  margin: 0px;
  border: 0px;
}

.AwayTeamBadge {
  order: 2;
  border-color: #393d3f;
  height: 3vw;
  width: 3vw;
  padding-left: 1em;
  /* padding-left: 0.5em;
  padding-right: 0.5em; */
  flex-basis: 1%;
  margin: 0px;
  border: 0px;
}

.TeamStats{
  display: flex;
  font-size: 0.9em;
  line-height: 2em;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin: 1em; */
}

.HomeTeamStats{
  flex-basis: 50%;
  /* padding: 2em; */
  /* border-right: #030052 0.5px solid; */
}

.HomeTeamStats li{
  padding: 0.5em;
  border-bottom: #030061 0.25px solid;
  color: #030061;
}

.AwayTeamStats{
  flex-basis: 50%;
  /* padding: 2em; */
  /* border-left: #030052 0.5px solid; */
}

.AwayTeamStats li{
  padding: 0.5em;
  border-bottom: #030061 0.25px solid;
  color: #030061;
}
.HomeTeamResults{
  flex-basis: 50%;

}

.AwayTeamResults{
  flex-basis: 50%;

}

.ResultedGame{
  line-height: 1em;
  padding: 1em;
  border-bottom: #030052 1px solid;
}

.MatchDate{
  padding-top: 1em;
  color: #030061;
}

.ComparisonBar{
/* display: inline; */
  margin-left: auto;
  margin-right: auto;
  flex-basis: 100%;
}

.Chart{
  width: fit-content;
}

.ChartContainer{
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.LiveScores{
  padding-top: 2em;
}

.Lineups {
  font-size: 1em;
}

.CurrentScore{
  font-size: 1.2em;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

h6.AttackingMomentum {
  padding: 1em;
}

.ContactText{
  font-size: 0.8em;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
}

.LoginForm{
  font-size: 0.8em;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 0em;
}

.LoginInput1 {
  /* height: 2em; */
  border: 1px solid #030052;
  border-bottom: 1px solid #030052;
  /* text-align: center;  */
  padding-left: 1em;    
  padding-right: 0; 
  width: 100%;
  color: #030052;
  line-height: 2.5em;
}
.LoginInput2 {
  /* height: 2em; */
  border-bottom: 1px solid #030052;
  border: 1px solid #030052;
  border-top: 0px;
  /* text-align: center;  */
  padding-left: 1em;    
  padding-right: 0; 
  margin-bottom: 0.5em;
  flex-basis: 90%;
  margin-right: 0.5em;
  color: #030052;
  line-height: 2.5em;
}

.ResetPasswordLink {
  background: none;
  border: none;
  color: #030052;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.ResetPasswordLink:hover {
  text-decoration: underline;
}

.LoginSignUp{
  /* flex-direction: column; */
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0em;
}

.CancelButton{
  background: none;
  border: none;
  color: #030052;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.CancelButton:hover {
  text-decoration: underline;
}
.SignUp{
  margin-top: 0em;
  font-size: 0.9em;
}

.text-blue-500{
  color: #030061;
}

.mb-6{
  width: 100%;
}

form input{
  display: block;
  opacity: 1;
  pointer-events: auto;

}

button.PaymentReturn{
  flex-basis: auto;
  margin-left: auto;
  margin-right: auto;
}

.PasswordContainer{
  display: flex;
}

button.TogglePasswordButton{
  flex-basis: 10%;
  margin: 0px;
  padding: 0em;
  margin-bottom: 0.5em;
  background: none;
  border: none;
  color: #030052;
  /* margin-left: auto;
  margin-right: auto;
  margin-top: 0em; */
}

.ResetPasswordField{
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  line-height: 2em;
  font-size: 1.2em;
}

.TeamOfTheSeason {
  flex: none;
}

button.SecondaryButtons{
  font-size: 1em;
  color: #030052;
  background-color: white;
  border: 1px solid #030052;
}

.LeagueList{
  padding: 1em;
  font-size: 1.1em;
}

.LeagueList li{
  margin: 1em;
}

.AIOverview{
  padding: 1em;
  line-height: 2em;
  color: #030052;
}

.AIDisclaimer{
  color: #030052;
}

.MembershipHeader{
  margin-left: 4em;
  margin-right: 4em;
}

.AIContainer{
  display: flex;
  color: #030052;
  font-size: 1em;
}

.AIMatchPreview{
  padding: 1em;
  padding-bottom: 1em;
  line-height: 1.75em;
  color: #030052;
}

.HomeAIInsights{
  flex-direction: column;
  text-align: justify;
  padding: 1em;
  line-height: 1.75em;
  flex-basis: 50%;
}

.HomeAIInsights ul{
  padding-top: 1em;
  font-size: 1em;
}

.AwayAIInsights{
  flex-direction: column;
  text-align: justify;
  padding: 1em;
  line-height: 1.75em;
  flex-basis: 50%;
}

.AwayAIInsights ul{
  padding-top: 1em;
  font-size: 1em;
}

.IndividualAIComponent{
  padding: 1em;
}

button.AIInsights {
  background-color: #fe8c00;
  color: black;
  margin: 1em;
  cursor: pointer;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */

}

button.AIInsightsLocked {
  background-color: #eaeaea;
  color:#030052;
  margin: 1em;
  cursor: pointer;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052; */

}

button.AIInsights button:active {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 #030052 inset;

}

.BarChart{
  height: 1000;
}
/* .XGSwiper{
} */

.swiper-wrapper{
  padding-bottom: 30px;
  display: flex;
  height: auto!;
  width: 100%;
}



.swiper-pagination{
  position:initial !important
}


.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 5px !important;
}

.swiper-pagination-bullet {
  background-color:#030061 !important;
  height: 1.5em !important;
  width: 1.5em !important;

}

.LockIcon{
  font-size: 2em;
}

.LockText{
  font-size: 1em;
  line-height: 1.5em;
}